import { createGlobalStyle } from 'styled-components';

import ResetCss from './reset-css';

const GlobalStyle = createGlobalStyle`
  ${ResetCss}
  
  body {
    margin: 0;
    font-family: ${(props) => props.theme.typography.family.body};
    color: ${(props) => props.theme.colors.grey.dark};
    font-size: 20px;
    font-weight: 400;
    line-height: normal;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 16px;
      line-height: 50px;
    }

    p {
      line-height: normal;
      
      b,
      strong {
        font-weight: 700;
      }
    }
    sup {
      vertical-align: super;
      font-size: smaller;
      align-self: end;
      a{
        color:${(props) => props.theme.colors.primary.default};
        margin-left: 4px;
        cursor: pointer;
        text-decoration:none !important;
      }
    }
    sub {
      vertical-align: sub;
      font-size: smaller;
      align-self:start;
    }
  }

  html {
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  /* Enzuzo Cookie Banner */

  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    #ez-cookie-notification{
      bottom: 85px !important;
    }
  }

.enzuzo-cookiebanner-container,
.notification-main,
.notification-bottom-right,
.bottom-right-medium,
.bottom-right-rounded,
.has-rounded,
.enzuzo-animate,
.enzuzo-none,
.enzuzo-shadow {
  opacity: 0 !important;

  &.show {
    opacity: 1 !important;
  }
}
  .ez-consent {
    --ez-preferences-switch-on: ${({ theme }) => theme.colors.primary.default} !important;

    .notification-main.enzuzo-shadow {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
    }

    .notification-button,
    button.enzuzo-modal-button {
      border-radius: 100px !important;
      border-width: 1px !important;
      font-family: ${({ theme }) => theme.typography.family.title} !important;
      font-size: ${({ theme }) => theme.typography.size.sm} !important;
      line-height: 1.25rem;
      padding: ${({ theme }) => `10px ${theme.spacings.md}`} !important;

      &:hover {
        background-color: ${({ theme }) => theme.colors.grey.default} !important;
        color: ${({ theme }) => theme.colors.white} !important;
        border-color: ${({ theme }) => theme.colors.grey.default} !important;
      }
    }

    .notification-main .notification-buttons {
      flex-direction: column-reverse;
      padding-top: ${({ theme }) => theme.spacings.sm};
    }

    .cookieButtonWrap {
      flex-direction: row !important;
    }

    #notificationManagerLink {
      margin: 0 !important;
      white-space: nowrap;
    }

    .enzuzo-poweredby-wrap {
      display: none !important;
    }
  }

  /* Enzuzo Cookie Banner */
.ez-consent {
  --ez-banner-link-color: ${({ theme }) => theme.colors.grey.dark} !important;
  --ez-preferences-switch-on:${({ theme }) => theme.colors.primary.default} !important;
  --ez-banner-decline-background-color: ${({ theme }) => theme.colors.white} !important;
  --ez-banner-decline-background-color-hover: ${({ theme }) =>
    theme.colors.grey.default} !important;
  --ez-banner-decline-text-color: ${({ theme }) => theme.colors.grey.dark} !important;
  --ez-banner-decline-border-color: ${({ theme }) => theme.colors.grey.dark} !important;
  --ez-banner-allow-all-background-color-hover: ${({ theme }) =>
    theme.colors.grey.default} !important;
  --ez-preferences-allow-selected-background-color-hover: ${({ theme }) =>
    theme.colors.grey.default} !important;

  .notification-main {
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
    flex-direction: column !important;
    max-width: 380px !important;
    padding: 32px !important;
    left: auto !important;
    right: 20px !important;
    bottom: 20px !important;
    transform: none !important;
    width: calc(100% - 64px) !important;

    .notification-text-medium {
      font-size: ${({ theme }) => theme.typography.size.sm}!important;
    }

    .notification-desc {
      p:last-of-type {
        display: inline !important;
      }

      #notificationPolicyLink {
        display: inline !important;
      }
    }

    .notification-buttons {
      flex-direction: column-reverse;
      padding-top: ${({ theme }) => theme.spacings.sm};
    }

    .closeTrigger {
      padding-right: 0 !important;
    }
  }

  .notification-button,
  button.enzuzo-modal-button,
  button.button-default,
  button.button-rounded,
  .notification-main button.button-default,
  .notification-main button.button-rounded {
    border-radius: 100px !important;
    border-width: 1px !important;
    font-family: ${({ theme }) => theme.typography.family.title} !important;
    font-size: ${({ theme }) => theme.typography.size.sm} !important;
    line-height: 1.25rem;
    padding: 10px  ${({ theme }) => theme.spacings.md} !important;
    transition: all 80ms ease-in-out !important;

    &:hover {
      color:  ${({ theme }) => theme.colors.white} !important;
      border-color: var(--ez-banner-allow-all-background-color-hover) !important;
    }
  }

  .cookieButtonWrap {
    flex-direction: row !important;
  }

  #notificationManagerLink {
    margin: 0 !important;
    white-space: nowrap;
  }

  .enzuzo-close-banner-button,
  .enzuzo-poweredby-wrap {
    display: none !important;
  }
  
  .enzuzo-modal-button-close {
    display: flex !important;
    align-items: center !important;
  }
}

`;

export default GlobalStyle;
