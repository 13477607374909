import { identify } from '@/lib/segment';

export const PERMISSION = {
  NECESSARY: 'necessary',
  FUNCTIONAL: 'functional',
  PERFORMANCE: 'performance',
  TARGETING: 'targeting',
  ANALYTICS: 'analytics',
};

export function identifyTracking({ permission }) {
  identify(null, {
    permission,
  });
}
