'use client';

import { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { config } from '@fortawesome/fontawesome-svg-core';

import { NavigationProvider } from '@/contexts/navigation.context';
import { renderSegmentScript, SegmentProvider } from '@/lib/segment';
import { montserratFont } from '@/utils/fonts';
import theme from '@/utils/styles-variables';
import GlobalStyle from '@/utils/global-styles';

import CookieBanner from '@/components/CookieBanner';

import '@fortawesome/fontawesome-svg-core/styles.css';

import { GTM } from '@/app/components/analytics/gtm';

config.autoAddCss = false;

export function Client({ children }) {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    let cookieBanner = document.getElementById('ez-cookie-notification');
    window.addEventListener('scroll', () => {
      if (window.scrollY > 50) {
        setScroll(true);
      }
    });

    if (scroll && cookieBanner !== null) {
      cookieBanner.classList.toggle('show');
    }

    () => window.removeEventListener('scroll');
  }, [scroll]);

  return (
    <ThemeProvider theme={theme}>
      <SegmentProvider>
        <CookieBanner />
        <NavigationProvider>
          <GlobalStyle />
          <div className={montserratFont.className}>
            <GTM />
            {renderSegmentScript()}
            {children}
          </div>
        </NavigationProvider>
      </SegmentProvider>
    </ThemeProvider>
  );
}
