'use client';

import { useEffect } from 'react';

import { identifyTracking, PERMISSION } from '@/utils/identify';
import { setConsentCookie } from '@/utils/cookie-consent';

function hasPermission(value) {
  return value === true;
}

function onInit(preferences) {
  const acceptAll = !Object.values(preferences).some((value) => !hasPermission(value));

  if (acceptAll) {
    handleAcceptAll();

    return;
  }

  const rejecttAll = Object.values(preferences).some((value) => !hasPermission(value));

  if (rejecttAll) {
    handleRejectAll();

    return;
  }

  handleAcceptSome({
    analytics: hasPermission(preferences.analytics_storage),
    marketing: hasPermission(preferences.ad_storage),
    preferences: hasPermission(preferences.personalization_storage),
  });
}

function handleAcceptAll() {
  identifyTracking({ permission: PERMISSION.FUNCTIONAL });
  setConsentCookie(true);
}

function handleRejectAll() {
  identifyTracking({ permission: PERMISSION.NECESSARY });
  setConsentCookie(false);
}

function handleAcceptSome(preferences) {
  if (Object.values(preferences).every((el) => el === true)) {
    identifyTracking({ permission: PERMISSION.FUNCTIONAL });
  } else {
    identifyTracking({
      permission: {
        ...(preferences.analytics ? { [PERMISSION.ANALYTICS]: true } : {}),
        ...(preferences.marketing ? { [PERMISSION.TARGETING]: true } : {}),
        ...(preferences.preferences ? { [PERMISSION.PERFORMANCE]: true } : {}),
      },
    });
  }
  setConsentCookie(JSON.stringify(preferences));
}

export default function CookieBanner() {
  useEffect(() => {
    /*
     * Enzuzo Callbacks
     */
    window.__enzuzoConfig = window.__enzuzoConfig ?? {};
    window.__enzuzoConfig = {
      callbacks: {
        init: (preferences) => {
          onInit(preferences);
        },
        acceptAll: () => {
          handleAcceptAll();
        },
        acceptSelected: (preferences) => {
          handleAcceptSome(preferences);
        },
        decline: () => {
          handleRejectAll();
        },
      },
    };
  }, []);

  return null;
}
